import { FunctionComponent } from 'react';

// Components
import Head from 'next/head';
import Image from '@Components/Utils/Image';
import Anchor from '@Components/Utils/Anchor';

// Styles
import styles from '@Pages/error/Error.module.scss';

const ContentNotFound: FunctionComponent = () => {
    return (
        <>
            <Head>
                <meta name="robots" content="noindex" />
            </Head>

            <div className={styles.wrapper}>
                <Image className={styles.image} src={'/assets/error/content_not_found.png'} />
                
                <h1 className={styles.title}>Trang không tồn tại!</h1>

                <div className={styles.text}>Chúng tôi rất tiếc, bạn hãy quay trở lại sau.</div>

                <Anchor href={'/'} className={styles.link}>Quay về trang chủ</Anchor>
            </div>
        </>
    );
};

export default ContentNotFound;
